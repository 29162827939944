$theme-colors: (
  primary: #f47e43,
  secondary: #4f70ce,
  light: #f3f3f3,
  dark: #434749,
  info: #ccc,
  success: #28a745,
  warning: #ffc107,
  danger: #dc3545,
  muted: #aaa
);

// @import '~angular-tree-component/dist/angular-tree-component.css';
// @import "./bootstrap/bootstrap";
// @import "./spinner";
// @import "./utils";
// @import "./rtl";
// @import "./responsive";

@media print {
  .breadcrumb {
    display: none !important;
  }
}

$input-disabled-bg:transparent;
$input-disabled-color: #aaa;


$dark: #373a3c;
$topnav-background-color: #373a3c;
$max-width: 1200px;
$primary: #f47e43;
$success: #28a745;


$font-family-sans-serif:
  // Cross-platform generic font family (default user interface font)
  system-ui,
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Windows
  "Segoe UI",
  // Android
  Roboto,
  // Basic web fallback
  "Helvetica Neue", Arial,
  // Linux
  "Noto Sans",
  "Liberation Sans",
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" ;

// $badge-color: $dark;



@import "../../node_modules/bootstrap/scss/bootstrap.scss";

body, html {
  height: 100%;
}

a {
  cursor: pointer;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

h2 {
  font-size: 1.3rem;
}

h3 {
  font-size: 1.10rem;
}

.uppercase {
  text-transform: uppercase;
}

button {
  // font-weight: 200 !important;
  background: transparent;
}

input.ng-invalid {
  // color: red !important;
}

input[readonly] {
  background: white !important;
}

.focus:focus:hover {
  background-color: #f79565 !important;
}

.searchbar {
  margin-bottom: auto;
  margin-top: auto;
  background-color: $dark;
  border-radius: 30px;
}

.search_input {
  border: 0;
  outline: 0;
  background: none;
  width: 80%;
  padding: 0px;
  line-height: 40px;
  transition: width .3s linear;
}

input-group {
  button {
    line-height: 40px;
  }
}

.search_input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent;
}


.tab-pane .list-group a:first-child {
  border-top: 0px;
}


.search_icon {
  height: 40px;
  width: 40px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}

.mobile-page {

  padding: 20px;

  .full-width {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0px;
  width: 100%;
  max-width: $max-width;

  > * {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
  }

  height: 100%;
  z-index: 1040;
  overflow: scroll;
  width: 100%;
  // max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  background: #efefef;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;


  // .navbar {
  //   max-width: $max-width;
  //   margin-left: auto;
  //   margin-right: auto;
  // }
}


.navbar2 {
  width: 100%;
  background-color: $dark;
  align-items: start !important;

  h1 {
    font-size: 1.3rem;
    margin-bottom: 0px;
    margin-top:5px;
    
  }

  &.fixed-top {
    border-bottom: 6px solid $primary !important;
    min-height: 50px !important;
  }

  // .navbar-brand {
  //   color: #fff;
  //   position: absolute;
  //   width: 100%;
  //   text-align: center;
  //   pointer-events: none;

  // }

  .nav-item > a {
    color: #999;

    &:hover {
      color: #fff;
    }

    button.btn-dark {
      // font-weight: 100;
      color: white;
      background: #444;
      border: none;
    }
  }
  .disabled {
    pointer-events: none;
  }

  button.btn-dark {
    font-weight: 100;
    color: white;
    background: #444;
    border: none;

    &:hover {
      background: #444 !important;
    }

    &:focus {
      box-shadow: none !important;
    }

    &.active {
      box-shadow: 0px !important;
      background: #f47e43 !important;
    }

    box-shadow: 0px !important

  }

  input, input:focus {
    // border: 0px;
    // background: #444;
    // margin-bottom: 6px !important;
    // color: #ddd !important;
    // box-shadow: 0px !important;
  }
}

.dropdown-item.disabled {
  color: #999 !important;
}

.desktop-page {
  display: flex;
  height: 100vh;
  flex-direction: column;
  flex-grow: 1;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: white;

  // tbody {
  //   display: block !important;
  //   overflow-y: scroll;
  //   height: calc(100% - 140px);
  // }

  .tableFixHead {
    // z-index:1000;
    overflow-y: auto;
    height: 90px;
  }

  .tableFixHead thead th, .tableFixHead thead tr {
    position: sticky;
    top: -2px;
  }

  .table-flush {
    margin-bottom: 0px;
    border: 0px;
  }

  /* Just common table stuff. Really. */
  table {
    width: 100%;
  }

  th, td {
    padding: 8px 16px;
  }

  th {
    background: #eee;
  }

  // table {
  //   height: 100%;
  //   overflow: hidden;
  // }

  table td, table th {
    white-space: nowrap;
    padding: 0px;
    padding-right: 5px;
    max-height: 100%;


    .badge {
      margin-left: 5px;
      padding: 5px;
    }
  }

  .dropdown-menu {
    box-shadow: 0px 0px 15px 0px #555;
  }

  .editable {
    margin: 0px;
    background: url(
    data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAIUlEQVQYV2NkQAKXL19uYITxQRxdXV2IAIwDYjMic0ACAN4vDv6/jr/tAAAAAElFTkSuQmCC
    ) repeat;


    
  }

 
  app-input-double-um {
     min-width: 100%;
    height: auto;
    display:table-cell;
  }

  
  

  td input, input-number input {
    border: 0px !important;
    outline: none !important;
    background: transparent !important;
  }

  .side-card {
    background: theme-color("light");
    // max-height: 115vh;
    overflow-y: scroll;
    border-left: 2px solid theme-color("muted");
    position: fixed;
    top: 0px;
    right: 0px;
    height: 100%;
    padding-top: 64px;


  }

  .canvas {
    overflow-x: scroll;
    overflow-y: scroll;

    .table-responsive {
      overflow-x: hidden;
    }
  }

  table .active {
    background: theme-color("light")
  }
}

/* MOBILE-FIRST PAGES */

app-forklift-page {
  @extend .desktop-page;
  padding: 0px;
  display: flex;
  // position:absolute;
  // max-height: 100%;

  video {
   height: calc(100vh - 55px);
   width: 100%;
  }
  
  canvas {
    position: absolute;
    z-index: 10000;
    pointer-events: none;
  }

  .scrollable {
    max-height: calc(100vh - 60px);
  }
  
  app-barcode-reader-dynamsoft {
    position:static;
  }
}


app-home {
  @extend .mobile-page;
  padding: 0px;
}

app-contact {
  @extend .mobile-page;
  padding: 0px;
  
  &.embedded {
    background: white;

    display: block;
    position: relative;
    max-width: 400px;
    max-height: 80%;

    text-align: left;;
    .fixed-top {
      position: relative;
    }

    .list-group {
      margin-top: 0px !important;
      max-height: 50vh;
      overflow: scroll;
    }

    .list-group-item {

      // @include button-variant(theme-color("light"), theme-color("primary"));
      // color: white;
      // border:none;
      // margin-top: 8px;
      // background: white;
    }

    .dropdown-divider {
      display: none;
    }

    .navbar {
      margin: 0px;
      padding: 0px;
      padding-left: 0px !important;
      padding-right: 0px !important;
      border-bottom: 0px !important;
      background: transparent;
      > *:not(.input-group) {
        // display: none;
      }

      .input-group {
        margin: 0px !important;
        .input-group-append {
          // display: none;
        }

        
      }
      
      input {
        // width: 100%;
      }
    }
  }
}

app-contact-detail {
  @extend .mobile-page;
}

app-edit-contact-detail {
  @extend .mobile-page;
}

app-cantieri-detail {
  @extend .mobile-page;
}

app-sale-picking-modal,
app-cantieri-gru,
app-cantieri-foto,
app-cantieri-checklist,
app-cantieri-attivita,
app-cantieri-presenze,
app-cantieri-search,
app-load,
app-load-detail,
app-load-picking,
app-picking-detail,
app-purchase,
app-offers-search,
app-sales,
app-package-detail,
app-shipping-search,
app-shipping-detail,
app-shipping-package,
app-search,
app-receipt-search,
app-receipt-detail,
app-search-detail,
app-search-results {
  @extend .mobile-page;
  padding: 0px;
}

app-root {
  display: block;
  height: 100%;
}

// app-load {
//   @extend .mobile-page;
//   padding: 0px;
// }

// app-load-detail {
//   @extend .mobile-page;
//   padding: 0px;
// }

// app-load-picking {
//   @extend .mobile-page;
//   padding: 0px;

//   input {
//     border: 0px;
//   }
// }


app-load-row-detail {
  // @extend .mobile-page;
  padding: 0px;
  background: black;

  .overlay {
    position: absolute;
    bottom: 30%;
    left: 2%;
    width: 96%;
    background: white;
    border-radius: 5px;
  }


}

/* DESKTOP-FIRST PAGES */
app-inventory,
app-sale-order-editor,
app-sales-detail,
app-sale-order-detail,
app-purchase-detail,
app-purchase-order-detail,
app-ddt-viewer,
app-deal-detail {
  // @extend .zoomed;
  @extend .desktop-page;
}

app-deal-detail {
  .col-4 {
    max-width: none;
  }
}

app-sale-order-detail {
  overflow: hidden !important;
}

app-order-detail {
  height: 100%;
  // background: red;
  table {
    // margin-bottom:40px
  }
}

/* COMPONENTS */



.zoomed {
  zoom: 85%;

  input[type=checkbox] {
    /* Double-sized Checkboxes */
    // transform: scale(1.2);
  }
}

.editable input {
  border: 0;
  outline: 0 !important;
  background: 0 0;
}

.list-label {
  font-size: 80%;
  font-weight: bold;
  margin-left: 15px;
  color: theme-color("dark")
}

.desktop-page {
  overflow: scroll;
}


.trello-like {
  display: flex;

  
}

.trello-like {
   ul {
    @include list-unstyled();
    width: 350px;
    min-width: 270px;
    border-radius: 4px;
    background: #ddd;
    margin-right: 20px;

    &.large {
      width: 550px;
      min-width: 370px;
    }
  }

   ul  li {
    cursor: pointer;
    padding: 10px;
    margin: 7px;
    box-shadow: 0px 0px 1px #000;
    border-radius: 4px;
    background: white;
  }

   ul > li:first-child {
    background: #ddd;
    box-shadow: none;
  }
}

app-deals-dashboard, 
app-sales-board, 
app-purchase-board,
app-production-detail,
app-production-search {
  @extend .desktop-page;


  .modal {
    background: rgba(0, 0, 0, 0.8);
    min-width: 500px;

    .modal-header {
      border: none;
    }
  }

  
}

app-production-detail {
  .table td {
    vertical-align: middle !important;
  }   
}


app-deals-dashboard {
  .nav {
    position: fixed;
    width: 100%;
  }
  .trello-like {
    margin-top: 110px;
  }
}


app-barcode-reader-dynamsoft, app-sale-picking-modal {
  user-select: none;
  -webkit-user-select: text;

  * {
    user-select: none;
    -webkit-user-select: text;
  }

  .counter {
    top: 20px;
    left: 20px;
    color: white;
    position: absolute;
    z-index: 100000;
  }

  display: flex;
  // justify-content: stt;
  flex-direction: column;
  z-index: 10000;
  min-width: 100%;
  min-height: 100vh;
  max-height: 100vh;
  height: 100%;
  overflow: hidden;
  background: black;
  position: fixed;
  top: 0px;
  left: 0px;

  #div-video-container {
    position: absolute !important;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    justify-content: center;
  }

  video {
    max-width: 100%;
    max-height: 100vh;
    overflow: hidden;
  }

  button.close {
    // position: absolute;
    // top:25px;
    // right: 25px;
    color: white;
  }

  button.keyboard {
    position: absolute;
    bottom: 65px;
    right: 25px;
    color: white;
  }

  button.undo {
    // position: absolute;
    // bottom: 55px;
    // left: 25px;
    color: white;
  }


  button.scan {
    user-select: none;
    background: red;
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: 50px;
    left: calc(50% - 30px);
    border-radius: 30px;
    border: 5px solid white;
    transition: .3s transform;

    &.active {
      transform: scale(1.3);
      transform-origin: center center;
      background: green;
    }


  }

  .progress {
    min-height: 42px;
  }

  .progress {
    // width: 80%;
    // margin-right: 10%;
    // position: absolute;
    max-width: 80%;
    min-width: 80%;
    // top: calc(70% - 25px);
    // left: calc(25%);
    input {
      // max-width: 50%;
    }
  }

  button.result {
    background: green;
    color: white;
    width: 200px;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 100px);
    z-index: 1000;
    // border-radius: 25px;
  }

  button {
    z-index: 100;
  }

  .input-wrapper {
    position:fixed;
    bottom: 100px;
    left: 0px;
    width: 100%;
    display: flex;
    z-index: 200;
    .input-group {
      flex-wrap: nowrap;
      max-width: 250px;
    }
  }

  
}

.footer {
  line-height: 40px;
  background: white;
  border-top: 1px solid black;
  text-align: right;
  padding: 6px 24px;
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;

  input {
    height: 2em;
    border:0px;
    font-size: 16px;
    font-weight: 400;
  }
}

.text-red {
  color: red !important;
}

.orders-content {
  max-height: 107vh;
}

.toast {
  font-size: 1.1rem;
  max-width: 500px;
}

.ancillary-orders {
  margin-top: 12rem;
  position: fixed;
  bottom:0px;
  height: 50%;
  width: 100%;
  background: white;
  border-top: 2px solid #333;
}

.contacts-divider {
  margin: 10px;
}

.pointer {
  cursor: pointer;
}

// .modal {
//   background: rgba(0, 0, 0, 0.7);
// }


app-picking-modal {

  .biginput {
    input, select, span {
      height: 80px;
      font-size: 48px;
      line-height: 68px;
      font-weight: normal !important;
      background: white;
      -webkit-appearance: none;

      &:focus {
        outline: none;
      }
    }

    sub {
      font-size: 200%;
    }

    input {
      border: 1px solid #aaa;
      border-radius: 5px 0px 0px 5px;
      padding-left: 10px;
    }

    select {
      border: 1px solid #aaa;
      border-left: 0px;
      border-radius: 0px 5px 5px 0px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.circle {
  border-radius: 50px;
  background: #f47e43;
  height: 32px;
  width: 32px;
  display: inline-flex;
  justify-content: center;
  line-height: 32px;
}


.dev {
  // .navbar {
  //   background-color: red;
  // }
}


.highlight:hover {
  background: red;
}

a.btn.btn-outline-dark:hover {
  color: #fff !important;
}


// .blocking-overlay {
//   position: fixed;
//   bottom: 10px;
//   right: 10px;
//   width: 200px;
//   height: 80px;
//   border-radius: 6px;
//   border: 2px solid theme-color("primary");
//   background: white;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 100;
// }

.blocking-overlay {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 300px;
  height: 100px;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 100;
}


// .pac-container {
//   // bad way to reposition google maps dropdown
//   transform: translateY(-50px) translateX(-35px);
// }

.card-stage {
  margin-bottom: 28px !important;
  width: 450px;
  width: 100%;
  min-width: 450px;
  min-width: 100%;
  min-height: 58px;
  

  .card-header {
    min-height: 58px;
  }

  .btn {
    min-width: 90px;
  }
}

.card-stage-sibling::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  background: linear-gradient(-45deg, white, white, white, transparent, transparent);
  border: 1px solid theme-color("muted");
  border-top: 0px;
  border-left: 0px;
  position: absolute;
  top: calc(100% - 20px);
  left: calc(50% - 20px);
  transform: rotate(45deg);
  z-index: 0;
}
.card-stage-child {
  margin-right: 28px;

}

.card-stage-child::before {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  background: linear-gradient(-45deg, white, white, white, transparent, transparent);
  border: 1px solid theme-color("muted");
  border-top: 0px;
  border-left: 0px;
  position: absolute;
  top: calc(100% - 20px);
  left: calc(50% - 20px);
  transform: rotate(45deg);
  z-index: 0;
  top: calc(50% - 20px);
  left: calc(100% - 19px);
  transform: rotate(-45deg);
}

.card-stage-last::after {
  display: none;
}





.modal-bg {
  background: rgba(0,0,0,0.9);
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 100000;
}


.text-no-wrap {
  white-space: nowrap;
}


.margin-top-as-navbar {
  margin-top: 65px;
}

app-production-detail {

}

app-picking-move-editor {
  display: block;
  
  table {
    border: 0px !important;
  }
  .spacer {
    background: transparent !important;
    border: 0px !important;
  }
}

app-barcode-reader-dynamsoft {
  z-index: 10000;
}

app-picking-leftovers {
  display: flex;
}


app-contact-picker {
  ul.list-inline {
    display: flex;
    flex-wrap: wrap;
  }
}

@include media-breakpoint-down(md) { 
  ul.list-inline {
    width: 100%;
    display: block;
    li {
      width: 100% !important;
    }
  }
 }


 app-order-inventory {
  background: theme-color("light");
  // max-height: 115vh;
  overflow-y: scroll;
  border-left: 2px solid theme-color("muted");
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100%;
  padding-top: 64px;
  z-index: 1000;
  min-width: 60%;
  max-width: 60%;
  overflow-y: scroll;
 }



  app-deal-budget {

    table {
      max-height: 200px;
      overflow: scroll;
      display: block;
    }
  }


  app-picking-package-location-picker {
    color: white;
    background-color: white;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    
    // .nav-item {
    //   button.nav-link {
    //     color: white !important;
        
    //   }
     
    // }

    .tab-content {
      height: 100%;
    }
    
    .tab-content > .active {
      display: flex;
      height: 100%;
    }

    .nav-tabs .nav-link.active {
      color: $dark !important;
    }


    .result {
      position: absolute;
      bottom:80px;
      left: 0%;
      width: 100%;
      height: calc(50% - 100px);
      z-index: 100000 +1;
      padding: 10px;
      font-size: 150%;      
    }
    
  }




  app-scanner {
    background-color: black;
    color: white;
    top: 0px;
    left: 0px;
    width: 100vw;
    // height: 100vh;
    overflow: hidden;
    z-index: 10000;

    
    z-index: 2000;
    
    > div {
      position: relative;
      width: 100vw;
    }

    canvas {
      top:0px;
      left: 0px;
      // width: 100%;
      height: 100%;
      z-index: 100000;
      position: absolute;
    }

    .code {
      justify-content: space-between;
      align-items: center;
    }


    .needToCreate {
      
    }


   
    
    .fa-close {
      // position:fixed;
      // top: 20px;
      // right: 20px;
      // z-index: 100001;
    }



 

    video {
      width: 100vw;
      height: calc(100vh - 100px);
      object-fit: none !important;
    }
  }


  .text-stroke {
    stroke: black;
  }





.scrollable { 
  overflow: scroll !important;
}


// v2
.changed {
  transition: all 0.2s ease-out;
  background-color: $primary !important;
  color: white !important;
  * {
    color: white !important;
  }
}


.desktop-page {
  .table-bordered { 
    tr {
      min-height: 50px;
    }
  }
}


app-picking-editor-page {
  display: flex;
  flex-direction: column;
  height: 100%;
}


app-order-header {
  display: flex;
  flex-direction: column;
}

app-keyboard-scanner {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: red;
  // box-shadow: 0px 0px 20px #333;
  color: white;
  margin-left: auto;

  &.active {
    color: $primary !important;
  }
}


app-navbar {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid $primary;

  .navbar {
    padding-bottom: 0px;
  }

  bar-loader {
    // display: block;
    width: 100%;
    height: 7px;
    // position: absolute;
    // bottom: -7px;
    .progress {
      height: 7px
    }
  }
  
  bar-loader .progress:after {
    display: block;
    content: ' ';
    background: transparent;
    width: 100%;
    height: 100%;
    // position: fixed;
    left: 0px;
    z-index: 2000000;
  
  }
  

}